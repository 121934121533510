import React from 'react';
import { CellProps, HeaderProps, Hooks } from 'react-table';
import { IndeterminateCheckbox } from '../components/Forms';

/**
 * Custom hook that adds checkbox to first cell in table header and table rows. Used in combination with
 * useRowSelect - https://react-table.tanstack.com/docs/api/useRowSelect
 * The header checkbox handles toggling all the rows, while individual rows are toggled by each row's checkbox.
 * Even though the component supports indeterminate checkbox state, the underlining grafana-ui Checkbox component
 * does not support it yet.
 *
 * @param hooks - passed from react-table
 */
export const useCheckboxes = (hooks: Hooks) => {
  hooks.visibleColumns.push((columns) => [
    {
      id: 'selection',
      width: '50px',
      className: 'checkbox',
      Header: ({ getToggleAllRowsSelectedProps }: HeaderProps<{}>) => (
        <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
      ),
      Cell: ({ row }: CellProps<{}>) => <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />,
    },
    ...columns,
  ]);
};
