import React, { FormEvent } from 'react';
import { TableToggleAllRowsSelectedProps } from 'react-table';
import { Checkbox } from '@grafana/ui';

export const IndeterminateCheckbox = React.forwardRef(function IndeterminateCheckbox(
  { indeterminate, ...rest }: Partial<TableToggleAllRowsSelectedProps>,
  ref: React.MutableRefObject<any>
) {
  const defaultRef = React.useRef(null);
  const resolvedRef = ref || defaultRef;

  React.useEffect(() => {
    if (resolvedRef.current) {
      resolvedRef.current.indeterminate = !!indeterminate;
    }
  }, [resolvedRef, indeterminate]);

  const onChange = (e: FormEvent<HTMLInputElement>) =>
    rest.onChange ? rest.onChange({ ...e, target: e.currentTarget }) : undefined;

  return <Checkbox ref={resolvedRef} {...rest} onChange={onChange} />;
});
